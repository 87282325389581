<template lang="pug">
.ui-table
  filter-panel(type="simple" :columns="columns" :showClose="showClear" :sticky="headerSticky" v-model="preFiltrationForm" @apply="$emit('apply')" @clear="$emit('clear')")
  ui-loader.loader(v-if="loader")
  template(v-else-if="data.length")
    .row(v-for="rowData of data" :key="rowData.id" :class="smallCell && 'simple'")
      template(v-for="(cellValue, cellName) in columns" )
        .cell(v-if="!cellValue.invisible" :key="cellName" :class="smallCell && 'small'" :style="`width: ${ cellValue.size ? cellValue.size + 'px' : 'auto' }; font-weight: ${ cellValue.bold ? 'bold' : 'normal' }`")
          slot(:name="cellName" :rowData="rowData") {{ rowData[cellName]  }}
  template(v-else)
    no-data(:text="noDataText" :description="noDataMode")
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';
import { useVModel } from "@vueuse/core";

import UiLoader from "@/components/ui/loader/UiLoader.vue";
import FilterPanel from "@/components/ui/filters/FilterPanel.vue";
import NoData from "@/components/ui/table/NoData.vue";

export default defineComponent({
  name: "UiTable",
  components: {
    NoData,
    UiLoader,
    FilterPanel,
  },
  emits: [
    'apply',
    'clear',
    'update:modelValue',
  ],
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    loader: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Object,
      default: () => {},
    },
    modelValue: {
      type: Object,
      default: () => {},
    },
    hasFilters: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    showClear: {
      type: Boolean,
      default: false,
    },
    headerSticky: {
      type: Boolean,
      default: false,
    },
    noDataText: {
      type: String,
      default: 'Нет данных',
    },
    noDataDescription: {
      type: String,
      default: '',
    },
    smallCell: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {

    const preFiltrationForm = useVModel(props, 'modelValue', emit);

    const noDataMode = computed(() => {
      if (props.noDataDescription) return props.noDataDescription;
      if (!props.hasError && !props.hasFilters) return 'none';
      if (props.hasError) return 'error';
      if (props.hasFilters) return 'filters';
      return 'none';
    })

    return {
      noDataMode,
      preFiltrationForm,
    }
  }
})
</script>

<style scoped lang="scss">
.ui-table {
  .row {
    display: flex;
    flex-flow: row;
    border-bottom: 1px solid #F1F0F0;

    &.simple {
      border-bottom: none;

      &:hover {
        background-color: #FAFAFA;
      }
    }
  }

  .cell {
    font-size: 14px;
    line-height: 16px;
    padding: 20px 8px;
    overflow-wrap: break-word;
    box-sizing: border-box;

    display: flex;
    flex-flow: column;
    gap: 6px;

    &.small {
      padding: 12px 8px;
    }
  }

  .loader {
    height: 320px !important;
  }
}
</style>
